// // statuses
export const STATUS_ERROR = 'ERROR';
export const STATUS_FAILED = 'FAILED';
export const STATUS_OPENED = 'OPENED';
export const STATUS_PENDING = 'PENDING';
export const STATUS_SUCCESS = 'SUCCESS';
export const STATUS_CREATED = 'CREATED';
export const STATUS_INACTIVE = 'INACTIVE';
export const STATUS_RESOLVED = 'RESOLVED';
export const STATUS_COMPLETED = 'COMPLETED';
export const STATUS_ALL_FAILED = 'ALL_FAILED';
export const STATUS_ALL_SUCCESS = 'ALL_SUCCESS';
export const STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const STATUS_PARTIAL_SUCCESS = 'PARTIAL_SUCCESS';
export const STATUS_PARTIALLY_SUCCESS = 'PARTIALLY_SUCCESS';
// intl
export const LANG_TYPE_GERMAN = 'GERMAN';
export const LANG_TYPE_ENGLISH = 'ENGLISH';
export const LANG_TYPE_UKRAINIAN = 'UKRAINIAN';
// user
export const USER_TYPE_DRIVER = 'DRIVER';
export const USER_TYPE_GENERAL = 'GENERAL';
export const USER_TYPE_CUSTOMER = 'CUSTOMER';
// portals
export const PORTAL_TYPE_CARRIER = 'carrier';
export const PORTAL_TYPE_CUSTOMER = 'customer';
export const PORTAL_TYPE_CARRIER_QUOTE = 'carrierQuote';
// load
export const LOAD_TYPE_TEL = 'TEL';
export const LOAD_TYPE_CLO = 'CLO';
// sequence
export const SEQUENCE_TYPE_CLO = 'CLO';
export const SEQUENCE_TYPE_TEL = 'TEL';
export const SEQUENCE_TYPE_TASK = 'TASK';
export const SEQUENCE_TYPE_FLEET = 'FLEET';
export const SEQUENCE_TYPE_CLAIM = 'CLAIM';
export const SEQUENCE_TYPE_ROUTE = 'ROUTE';
export const SEQUENCE_TYPE_CARRIER = 'CARRIER';
export const SEQUENCE_TYPE_INVOICE = 'INVOICE';
export const SEQUENCE_TYPE_BRANCH = 'ENTERPRISE';
export const SEQUENCE_TYPE_TEMPLATES = 'TEMPLATES';
// reference scopes
export const REFERENCE_SCOPE_TYPE_CLO = 'CLO';
export const REFERENCE_SCOPE_TYPE_TEL = 'TEL';
export const REFERENCE_SCOPE_TYPE_USER = 'user';
export const REFERENCE_SCOPE_TYPE_ITEM = 'item';
export const REFERENCE_SCOPE_TYPE_ROUTE = 'route';
export const REFERENCE_SCOPE_TYPE_BRANCH = 'branch';
export const REFERENCE_SCOPE_TYPE_CARRIER = 'carrier';
export const REFERENCE_SCOPE_TYPE_LOCATION = 'location';
export const REFERENCE_SCOPE_TYPE_LOAD_EVENT = 'load-event';
export const REFERENCE_SCOPE_TYPE_FLEET_TRUCK = 'fleet-truck';
export const REFERENCE_SCOPE_TYPE_FLEET_DRIVER = 'fleet-driver';
export const REFERENCE_SCOPE_TYPE_FLEET_VENDOR = 'fleet-vendor';
export const REFERENCE_SCOPE_TYPE_FLEET_TRAILER = 'fleet-trailer';
export const REFERENCE_SCOPE_TYPE_FLEET_INVOICE = 'fleet-invoice';
export const REFERENCE_SCOPE_TYPE_CARRIER_INVOICE = 'carrier-invoice';
export const REFERENCE_SCOPE_TYPE_CUSTOMER_INVOICE = 'customer-invoice';
// event
export const EVENT_TYPE_DROP = 'DROP';
export const EVENT_TYPE_PICKUP = 'PICKUP';
// stop
export const STOP_TYPE_TERMINAL = 'TERMINAL';
export const STOP_TYPE_INDEPENDENT = 'INDEPENDENT';
// stop status
export const STOP_STATUS_TYPE_PENDING = 'PENDING';
// TODO: check UI and mobile logic for 'ARRIVED' status
export const STOP_STATUS_TYPE_ARRIVED = 'ARRIVED';
export const STOP_STATUS_TYPE_COMPLETED = 'COMPLETED';
export const STOP_STATUS_TYPE_CHECKED_IN = 'CHECKED_IN';
// distance calculation
export const DISTANCE_CALCULATION_TYPE_AMAZON = 'AMAZON';
export const DISTANCE_CALCULATION_TYPE_GOOGLE = 'GOOGLE';
export const DISTANCE_CALCULATION_TYPE_TOM_TOM = 'TOM_TOM';
export const DISTANCE_CALCULATION_TYPE_PC_MILER = 'PC_MILER';
export const DISTANCE_CALCULATION_TYPE_PRO_MILES = 'PRO_MILES';
export const DISTANCE_CALCULATION_TYPE_RAND_MCNALLY = 'RAND_MCNALLY';
// rate
export const RATE_TYPE_FLEET_RATE = 'FLEET_RATE';
export const RATE_TYPE_CARRIER_RATE = 'CARRIER_RATE';
// charge
export const CHARGE_TYPE_FUEL = 'fuel';
export const CHARGE_TYPE_MAIN = 'main';
export const CHARGE_TYPE_DISCOUNT = 'discount';
export const CHARGE_TYPE_CANCEL_CLO = 'cancelCLO';
export const CHARGE_TYPE_ADDITIONAL = 'additional';
// charge rate
export const CHARGE_RATE_TYPE_TIME = 'TIME';
export const CHARGE_RATE_TYPE_STOP = 'STOP';
export const CHARGE_RATE_TYPE_FLAT = 'FLAT';
export const CHARGE_RATE_TYPE_VOLUME = 'VOLUME';
export const CHARGE_RATE_TYPE_WEIGHT = 'WEIGHT';
export const CHARGE_RATE_TYPE_DISTANCE = 'DISTANCE';
export const CHARGE_RATE_TYPE_QUANTITY = 'QUANTITY';
export const CHARGE_RATE_TYPE_DISCOUNT = 'DISCOUNT';
export const CHARGE_RATE_TYPE_LINE_HAUL = 'LINEHAUL';
export const CHARGE_RATE_TYPE_RATE_TOTAL = 'RATE_TOTAL';
export const CHARGE_RATE_TYPE_CUSTOMER_RATE = 'CUSTOMER_RATE';
export const CHARGE_RATE_TYPE_TAXABLE_TOTAL = 'TAXABLE_TOTAL';
export const CHARGE_RATE_TYPE_DEADHEAD_DISTANCE = 'DEADHEAD_DISTANCE';
export const CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL = 'CUSTOMER_LINE_HAUL';
// charge rate uom
export const CHARGE_RATE_UNIT_TYPE_DAY = 'DAY';
export const CHARGE_RATE_UNIT_TYPE_FLAT = 'FLAT';
export const CHARGE_RATE_UNIT_TYPE_HOUR = 'HOUR';
export const CHARGE_RATE_UNIT_TYPE_PERCENT = 'PERCENT';
// document printable section
export const DOCUMENT_PRINTABLE_SECTION_TEL = 'TEL';
export const DOCUMENT_PRINTABLE_SECTION_CLO = 'CLO';
export const DOCUMENT_PRINTABLE_SECTION_IFTA = 'IFTA';
export const DOCUMENT_PRINTABLE_SECTION_TRIP = 'TRIP';
export const DOCUMENT_PRINTABLE_SECTION_ROUTE = 'ROUTE';
export const DOCUMENT_PRINTABLE_SECTION_ORDER = 'ORDER';
export const DOCUMENT_PRINTABLE_SECTION_CLO_REPORT = 'CLO_REPORT';
export const DOCUMENT_PRINTABLE_SECTION_TEL_REPORT = 'TEL_REPORT';
export const DOCUMENT_PRINTABLE_SECTION_FLEET_TRUCK = 'FLEET_TRUCK';
export const DOCUMENT_PRINTABLE_SECTION_ORDER_QUOTE = 'ORDER_QUOTE';
export const DOCUMENT_PRINTABLE_SECTION_FLEET_DRIVER = 'FLEET_DRIVER';
export const DOCUMENT_PRINTABLE_SECTION_FLEET_TRAILER = 'FLEET_TRAILER';
export const DOCUMENT_PRINTABLE_SECTION_FLEET_INVOICE = 'FLEET_INVOICE';
export const DOCUMENT_PRINTABLE_SECTION_DRIVER_PAYROLL = 'DRIVER_PAYROLL';
export const DOCUMENT_PRINTABLE_SECTION_CARRIER_INVOICE = 'CARRIER_INVOICE';
export const DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE = 'CUSTOMER_INVOICE';
export const DOCUMENT_PRINTABLE_SECTION_FLEET_TRUCK_SERVICE = 'FLEET_TRUCK_SERVICE';
export const DOCUMENT_PRINTABLE_SECTION_FLEET_INVOICE_REPORT = 'FLEET_INVOICE_REPORT';
export const DOCUMENT_PRINTABLE_SECTION_FLEET_VENDOR_PAYROLL = 'FLEET_VENDOR_PAYROLL';
export const DOCUMENT_PRINTABLE_SECTION_FLEET_TRAILER_SERVICE = 'FLEET_TRAILER_SERVICE';
export const DOCUMENT_PRINTABLE_SECTION_DRIVER_PAYROLL_REPORT = 'DRIVER_PAYROLL_REPORT';
export const DOCUMENT_PRINTABLE_SECTION_CARRIER_INVOICE_REPORT = 'CARRIER_INVOICE_REPORT';
export const DOCUMENT_PRINTABLE_SECTION_CUSTOMER_INVOICE_REPORT = 'CUSTOMER_INVOICE_REPORT';
export const DOCUMENT_PRINTABLE_SECTION_CUSTOMER_MASTER_INVOICE = 'CUSTOMER_MASTER_INVOICE';
export const DOCUMENT_PRINTABLE_SECTION_VENDOR_DRIVER_RATE_LIST = 'VENDOR_DRIVER_RATE_LIST';
export const DOCUMENT_PRINTABLE_SECTION_FLEET_DISPATCH_DOCUMENTS = 'FLEET_DISPATCH_DOCUMENT';
export const DOCUMENT_PRINTABLE_SECTION_FLEET_VENDOR_PAYROLL_NEW = 'FLEET_VENDOR_PAYROLL_NEW';
export const DOCUMENT_PRINTABLE_SECTION_CARRIER_DISPATCH_DOCUMENTS = 'CARRIER_DISPATCH_DOCUMENT';
export const DOCUMENT_PRINTABLE_SECTION_FLEET_VENDOR_INVOICE_REPORT = 'FLEET_VENDOR_INVOICE_REPORT';
export const DOCUMENT_PRINTABLE_SECTION_FLEET_VENDOR_PAYROLL_REPORT = 'FLEET_VENDOR_PAYROLL_REPORT';
export const DOCUMENT_PRINTABLE_SECTION_CUSTOMER_MASTER_INVOICE_REPORT = 'CUSTOMER_MASTER_INVOICE_REPORT';
// clo creation mode
export const TEL_CREATION_MODE_NO_TEL = 'NO_TEL';
export const TEL_CREATION_MODE_MULTI_TEL = 'MULTI_TEL';
export const TEL_CREATION_MODE_SINGLE_TEL = 'SINGLE_TEL';
// document proof
export const DOCUMENT_PROOF_TYPE_POD = 'POD';
export const DOCUMENT_PROOF_TYPE_POP = 'POP';
export const DOCUMENT_PROOF_TYPE_GENERAL_VALUE = 'NONE';
export const DOCUMENT_PROOF_TYPE_GENERAL_NAME = 'General';
// status message code status types
export const STATUS_CODE_STATUS_TYPE_ESTIMATED = 'ESTIMATED';
export const STATUS_CODE_STATUS_TYPE_DROP_COMPLETED = 'DROP_COMPLETED';
export const STATUS_CODE_STATUS_TYPE_STOP_CHECKED_IN = 'STOP_CHECKED_IN';
export const STATUS_CODE_STATUS_TYPE_DROP_CHECKED_IN = 'DROP_CHECKED_IN';
export const STATUS_CODE_STATUS_TYPE_PICKUP_COMPLETED = 'PICKUP_COMPLETED';
export const STATUS_CODE_STATUS_TYPE_PICKUP_CHECKED_IN = 'PICKUP_CHECKED_IN';
// status message warning level
export const STATUS_MESSAGE_WARNING_LEVEL_TYPE_WARNING = 'Warning';
export const STATUS_MESSAGE_WARNING_LEVEL_TYPE_CRITICAL = 'Critical';
// notification warning level
export const NOTIFICATION_WARNING_LEVEL_TYPE_INFO = 'INFO';
export const NOTIFICATION_WARNING_LEVEL_TYPE_WARNING = 'WARNING';
export const NOTIFICATION_WARNING_LEVEL_TYPE_CRITICAL = 'CRITICAL';
// notification warning level value
export const NOTIFICATION_WARNING_LEVEL_VALUE_ALL = 'ALL';
export const NOTIFICATION_WARNING_LEVEL_VALUE_CRITICAL = 'CRITICAL';
export const NOTIFICATION_WARNING_LEVEL_VALUE_WARNING_AND_CRITICAL = 'WARNING_AND_CRITICAL';
// notification object
export const NOTIFICATION_OBJECT_TYPE_CLO = 'CLO';
export const NOTIFICATION_OBJECT_TYPE_TEL = 'TEL';
export const NOTIFICATION_OBJECT_TYPE_EDI = 'EDI';
export const NOTIFICATION_OBJECT_TYPE_IFTA = 'IFTA';
export const NOTIFICATION_OBJECT_TYPE_TRUCK = 'TRUCK';
export const NOTIFICATION_OBJECT_TYPE_DRIVER = 'DRIVER';
export const NOTIFICATION_OBJECT_TYPE_TRAILER = 'TRAILER';
export const NOTIFICATION_OBJECT_TYPE_CARRIER = 'CARRIER';
export const NOTIFICATION_OBJECT_TYPE_ORDER_QUOTE = 'ORDER_QUOTE';
// specific document features
export const DOCUMENT_TEMPLATE_SPECIFIC_FEATURE_EVENT_RELATED = 'EVENT_RELATED';
export const DOCUMENT_TEMPLATE_SPECIFIC_FEATURE_ALLOW_ZERO_RATE = 'ALLOW_ZERO_RATE';
export const DOCUMENT_TEMPLATE_SPECIFIC_FEATURE_DAILY_DRIVER_HOURS_ADD_HOS_LOGS = 'DAILY_DRIVER_HOURS_ADD_HOS_LOGS';
export const DOCUMENT_TEMPLATE_SPECIFIC_FEATURE_VENDOR_PAYROLL_SCIP_RELATED_TRIP_INVOICES =
  'VENDOR_PAYROLL_SCIP_RELATED_TRIP_INVOICES';
// truck
export const TRUCK_TYPE_MULTI_CARRIERS = 'MULTI_CARRIERS';
// report
export const EDI_REPORT = 'EDI';
export const TEL_REPORT = 'TEL';
export const CLO_REPORT = 'CLO';
export const USER_REPORT = 'USER';
export const IFTA_REPORT = 'IFTA';
export const ITEM_REPORT = 'ITEM';
export const CLAIM_REPORT = 'CLAIM';
export const CHART_REPORT = 'CHART';
export const ROUTE_REPORT = 'ROUTE';
export const REPORT_REPORT = 'REPORT';
export const CARRIER_REPORT = 'CARRIER';
export const REPORT_TYPE = 'reportType';
export const PAYROLL_REPORT = 'PAYROLL';
export const BRANCH_REPORT = 'ENTERPRISE';
export const REFERENCE_REPORT = 'REFERENCE';
export const PIVOT_CLO_REPORT = 'PIVOT_CLO';
export const PIVOT_TEL_REPORT = 'PIVOT_TEL';
export const ROUTE_TEL_REPORT = 'ROUTE_TEL';
export const ROUTE_CLO_REPORT = 'ROUTE_CLO';
export const FUEL_CARD_REPORT = 'FUEL_CARD';
export const CLO_EVENT_REPORT = 'CLO_EVENT';
export const TEL_EVENT_REPORT = 'TEL_EVENT';
export const WORK_ORDER_REPORT = 'WORK_ORDER';
export const TEL_BY_CLO_REPORT = 'TEL_BY_CLO';
export const INSPECTION_REPORT = 'INSPECTION';
export const INVOICE_EDI_REPORT = 'INVOICE_EDI';
export const ORDER_QUOTE_REPORT = 'ORDER_QUOTE';
export const TOLL_CHARGE_REPORT = 'TOLL_CHARGE';
export const FLEET_TRUCK_REPORT = 'FLEET_TRUCK';
export const CONTACT_BOOK_REPORT = 'CONTACT_BOOK';
export const CLO_TEMPLATE_REPORT = 'CLO_TEMPLATE';
export const FLEET_DRIVER_REPORT = 'FLEET_DRIVER';
export const FLEET_VENDOR_REPORT = 'FLEET_VENDOR';
export const ORDER_REQUEST_REPORT = 'ORDER_REQUEST';
export const FLEET_TRAILER_REPORT = 'FLEET_TRAILER';
export const IMPORT_REPORT_REPORT = 'IMPORT_REPORT';
export const ROUTE_BY_LOAD_REPORT = 'ROUTE_BY_LOAD';
export const SERVICE_VENDOR_REPORT = 'SERVICE_VENDOR';
export const ROUTE_TEMPLATE_REPORT = 'ROUTE_TEMPLATE';
export const TASK_MANAGEMENT_REPORT = 'TASK_MANAGEMENT';
export const CLO_RATE_CHARGE_REPORT = 'CLO_RATE_CHARGE';
export const TEL_RATE_CHARGE_REPORT = 'TEL_RATE_CHARGE';
export const CARRIER_INVOICE_REPORT = 'CARRIER_INVOICE';
export const INSPECTION_TEMPLATE = 'INSPECTION_TEMPLATE';
export const CARRIER_CONTRACT_REPORT = 'CARRIER_CONTRACT';
export const CUSTOMER_INVOICE_REPORT = 'CUSTOMER_INVOICE';
export const LOS_SUBSCRIPTION_REPORT = 'LOS_SUBSCRIPTION';
export const VENDOR_INVOICE_REPORT = 'FLEET_VENDOR_INVOICE';
export const VENDOR_PAYROLL_REPORT = 'FLEET_VENDOR_PAYROLL';
export const CUSTOMER_CONTRACT_REPORT = 'CUSTOMER_CONTRACT';
export const LOCATION_TEMPLATE_REPORT = 'LOCATION_TEMPLATE';
export const CARRIER_STATISTIC_REPORT = 'CARRIER_STATISTIC';
export const CARRIER_INSURANCE_REPORT = 'CARRIER_INSURANCE';
export const LOAD_BOARD_FILTER_REPORT = 'LOAD_BOARD_FILTER';
export const DRIVER_PAYROLL_INVOICE_REPORT = 'FLEET_INVOICE';
export const CONTAINER_TEMPLATE_REPORT = 'CONTAINER_TEMPLATE';
export const SHARED_ACCESSORIAL_REPORT = 'SHARED_ASSESSORIAL';
export const FLEET_SERVICE_ISSUE_REPORT = 'FLEET_SERVICE_ISSUE';
export const EXCEL_RATING_RESULT_REPORT = 'EXCEL_RATING_RESULT';
export const CUSTOMER_PORTAL_CLO_REPORT = 'CUSTOMER_PORTAL_CLO';
export const EXTERNAL_LOAD_BOARD_REPORT = 'EXTERNAL_LOAD_BOARD';
export const DRIVER_COMPENSATION_REPORT = 'driver-compensation';
export const VENDOR_COMPENSATION_REPORT = 'vendor-compensation';
export const PIVOT_ROUTE_BY_LOAD_REPORT = 'PIVOT_ROUTE_BY_LOAD';
export const CARRIER_RATE_REQUEST_REPORT = 'CARRIER_RATE_REQUEST';
export const PIVOT_DRIVER_PAYROLL_REPORT = 'PIVOT_DRIVER_PAYROLL';
export const PIVOT_VENDOR_PAYROLL_REPORT = 'PIVOT_VENDOR_PAYROLL';
export const COMPENSATION_TEMPLATE_REPORT = 'COMPENSATION_TEMPLATE';
export const CARRIER_CONTRACT_RATE_REPORT = 'carrier-contract-rate';
export const PIVOT_PAYROLL_CHARGES_REPORT = 'PIVOT_PAYROLL_CHARGES';
export const FLEET_SHARED_COMPONENT_REPORT = 'FLEET_SHARED_COMPONENT';
export const SERVICE_VENDOR_INVOICE_REPORT = 'SERVICE_VENDOR_INVOICE';
export const PIVOT_CUSTOMER_INVOICE_REPORT = 'CUSTOMER_INVOICE_PIVOT';
export const GEO_FENCING_LOCATION_REPORT = 'FLEET_GEOFENCING_LOCATION';
export const CUSTOMER_MASTER_INVOICE_REPORT = 'CUSTOMER_MASTER_INVOICE';
export const FLEET_DRIVER_ONBOARDING_REPORT = 'FLEET_DRIVER_ONBOARDING';
export const FLEET_EQUIPMENT_SERVICE_REPORT = 'FLEET_EQUIPMENT_SERVICE';
export const FLEET_DRIVER_ASSESSORIAL_REPORT = 'FLEET_DRIVER_ASSESSORIAL';
export const FLEET_VENDOR_ASSESSORIAL_REPORT = 'FLEET_VENDOR_ASSESSORIAL';
export const FLEET_DRIVER_COMPENSATION_REPORT = 'FLEET_DRIVER_COMPENSATION';
export const FLEET_VENDOR_COMPENSATION_REPORT = 'FLEET_VENDOR_COMPENSATION';
export const CARRIER_CONTRACT_RATE_PRICE_REPORT = 'CARRIER_CONTRACT_RATE_PRICE';
export const PIVOT_FLEET_EQUIPMENT_SERVICE_REPORT = 'PIVOT_FLEET_EQUIPMENT_SERVICE';
export const CARRIER_CONTRACT_AWARDED_LINE_REPORT = 'CARRIER_CONTRACT_AWARDED_LINE';
export const CUSTOMER_CONTRACT_AWARDED_LINE_REPORT = 'CUSTOMER_CONTRACT_AWARDED_LINE';
export const PIVOT_VENDOR_PAYROLL_CHARGES_REPORT = 'PIVOT_FLEET_VENDOR_PAYROLL_CHARGES';
export const FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT = 'FLEET_DRIVER_PAYROLL_ASSESSORIAL';
export const FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT = 'FLEET_VENDOR_PAYROLL_ASSESSORIAL';
// report view type
export const REPORT_VIEW_TYPE_LIST = 'LIST';
export const REPORT_VIEW_TYPE_PIVOT = 'PIVOT';
// fleet-integration
export const FLEET_INTEGRATION_TYPE_EFS = 'EFS';
export const FLEET_INTEGRATION_SPIREON = 'SPIREON';
export const FLEET_INTEGRATION_TYPE_I_PASS = 'I_PASS';
export const FLEET_INTEGRATION_TYPE_SAMSARA = 'SAMSARA';
export const FLEET_INTEGRATION_TYPE_SKYBITZ = 'SKYBITZ';
export const FLEET_INTEGRATION_TYPE_PRE_PASS = 'PRE_PASS';
export const FLEET_INTEGRATION_TYPE_BESTPASS = 'BESTPASS';
export const FLEET_INTEGRATION_TYPE_SELF_TOLL = 'SELF_TOLL';
export const FLEET_INTEGRATION_TYPE_POWER_FLEET = 'POWER_FLEET';
export const FLEET_INTEGRATION_TYPE_KEEP_TRUCKIN = 'KEEP_TRUCKIN';
// configuration-integration
export const CONFIGURATION_INTEGRATION_TYPE_FOUR_KITES = 'FOUR_KITES';
// customer integration configs
export const CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_PROJECT_44 = 'PROJECT_44';
export const CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_FOUR_KITES = 'FOUR_KITES';
export const CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_MACRO_POINT = 'MACRO_POINT';
export const CUSTOMER_INTEGRATION_CONFIGS_INTEGRATION_TYPE_CH_ROBINSON = 'CH_ROBINSON';
// gps integration config
export const GPS_INTEGRATION_TYPE_SAMSARA = 'SAMSARA';
export const GPS_INTEGRATION_TYPE_VERIZON = 'VERIZON';
export const GPS_INTEGRATION_TYPE_GEO_TAB = 'GEO_TAB';
export const GPS_INTEGRATION_TYPE_CLUB_ELD = 'CLUB_ELD';
export const GPS_INTEGRATION_TYPE_OMNITRACS = 'OMNITRACS';
export const GPS_INTEGRATION_TYPE_BLUE_STAR = 'BLUE_STAR';
export const GPS_INTEGRATION_TYPE_MASTER_ELD = 'MASTER_ELD';
export const GPS_INTEGRATION_TYPE_KEEP_TRUCKIN = 'KEEP_TRUCKIN';
export const GPS_INTEGRATION_TYPE_TRACK_ENSURE = 'TRACK_ENSURE';
// fleet-driver
export const DRIVER_ENDORSEMENTS_TANK = 'Tank';
export const DRIVER_ENDORSEMENTS_OTHER = 'Other';
export const DUTY_STATUS_CODE = 'dutyStatusCode';
export const DRIVER_ENDORSEMENTS_TOW_TRUCK = 'Tow Truck';
export const DRIVER_ENDORSEMENTS_METAL_COIL = 'Metal Coil';
export const DRIVER_ENDORSEMENTS_X_ENDORSEMENT = 'X Endorsement';
export const DRIVER_ENDORSEMENTS_TANK_VEHICLES = 'Tank Vehicles';
export const DRIVER_ENDORSEMENTS_NO_ENDORSEMENT = 'No Endorsement';
export const DRIVER_ENDORSEMENTS_DOUBLES_TRIPLES = 'Doubles/Triples';
export const DRIVER_ENDORSEMENTS_PASSENGER_TRANSPORT = 'Passenger Transport';
export const DRIVER_ENDORSEMENTS_HAZARDOUS_MATERIALS = 'Hazardous Materials';
export const DRIVER_ENDORSEMENTS_FARM_CLASS_A_VEHICLES = 'Farm Class A Vehicles';
export const DRIVER_ENDORSEMENTS_FARM_CLASS_B_VEHICLES = 'Farm Class B Vehicles';
export const DRIVER_ENDORSEMENTS_RECREATIONAL_VEHICLES_GWR = 'Recreational Vehicles GVWR over 26,000 lbs.';
// EDI
export const EDI_TRANSACTION_ACTION_ERROR = 'ERROR';
export const EDI_TRANSACTION_ACTION_CANCEL = 'CANCEL';
export const EDI_TRANSACTION_ACTION_CREATE = 'CREATE';
export const EDI_TRANSACTION_ACTION_UPDATE = 'UPDATE';
export const EDI_TRANSACTION_ACTION_REPLACE = 'REPLACE';
// EDI STATUS TYPE
export const EDI_STATUS_TYPE_ERROR = 'ERROR';
export const EDI_STATUS_TYPE_PENDING = 'PENDING';
export const EDI_STATUS_TYPE_ACCEPTED = 'ACCEPTED';
export const EDI_STATUS_TYPE_RETRYING = 'RETRYING';
export const EDI_STATUS_TYPE_DECLINED = 'DECLINED';
export const EDI_STATUS_TYPE_UNASSIGNED = 'UNASSIGNED';
// EDI LOADER SENDER IDENTIFICATION TYPES
export const EDI_LOADER_SENDER_IDENTIFICATION_TYPE_SENDER_ID = 'SENDER_ID';
export const EDI_LOADER_SENDER_IDENTIFICATION_TYPE_ACCOUNT_CODE_REFERENCE = 'ACCOUNT_CODE_REFERENCE';
export const EDI_LOADER_SENDER_IDENTIFICATION_TYPE_SHIPPER_IDENTIFICATION_CODE = 'SHIPPER_IDENTIFICATION_CODE';
// SPECIAL INSTRUCTIONS FIELD TYPES
export const SPECIAL_INSTRUCTIONS_FIELD_TYPE_SPECIAL_INSTRUCTIONS = 'SPECIAL_INSTRUCTIONS';
export const SPECIAL_INSTRUCTIONS_FIELD_TYPE_INTERNAL_INSTRUCTIONS = 'INTERNAL_INSTRUCTIONS';
// QUOTES
export const QUOTES_TRANSACTION_ACTION_ERROR = 'ERROR';
export const QUOTES_TRANSACTION_ACTION_CANCEL = 'CANCEL';
export const QUOTES_TRANSACTION_ACTION_CREATE = 'CREATE';
export const QUOTES_TRANSACTION_ACTION_UPDATE = 'UPDATE';
// ORDER QUOTE STATUS TYPES
export const ORDER_QUOTE_STATUS_TYPE_QUOTED = 'QUOTED';
export const ORDER_QUOTE_STATUS_TYPE_EXPIRED = 'EXPIRED';
export const ORDER_QUOTE_STATUS_TYPE_CANCELED = 'CANCELED';
export const ORDER_QUOTE_STATUS_TYPE_DECLINED = 'DECLINED';
export const ORDER_QUOTE_STATUS_TYPE_ACCEPTED = 'ACCEPTED';
export const ORDER_QUOTE_STATUS_TYPE_PROCESSED = 'PROCESSED';
export const ORDER_QUOTE_STATUS_TYPE_RATE_REQUESTED = 'RATE_REQUESTED';
export const ORDER_QUOTE_STATUS_TYPE_SENT_TO_CUSTOMER = 'SENT_TO_CUSTOMER';
// QUANTITY
export const ITEM_QUANTITY_UNIT_BOX = 'BOX';
export const ITEM_QUANTITY_UNIT_BAG = 'BAG';
export const ITEM_QUANTITY_UNIT_BIN = 'BIN';
export const ITEM_QUANTITY_UNIT_SACK = 'SACK';
export const ITEM_QUANTITY_UNIT_PAIL = 'PAIL';
export const ITEM_QUANTITY_UNIT_TOTE = 'TOTE';
export const ITEM_QUANTITY_UNIT_RACK = 'RACK';
export const ITEM_QUANTITY_UNIT_ROLL = 'ROLL';
export const ITEM_QUANTITY_UNIT_CASES = 'CASES';
export const ITEM_QUANTITY_UNIT_DRUMS = 'DRUMS';
export const ITEM_QUANTITY_UNIT_SKIDS = 'SKIDS';
export const ITEM_QUANTITY_UNIT_BALES = 'BALES';
export const ITEM_QUANTITY_UNIT_CRATES = 'CRATES';
export const ITEM_QUANTITY_UNIT_GALLON = 'GALLON';
export const ITEM_QUANTITY_UNIT_PALLET = 'PALLET';
export const ITEM_QUANTITY_UNIT_PIECES = 'PIECES';
export const ITEM_QUANTITY_UNIT_BUNDLES = 'BUNDLES';
export const ITEM_QUANTITY_UNIT_CARTONS = 'CARTONS';
export const ITEM_QUANTITY_UNIT_GAYLORD = 'GAYLORD';
export const ITEM_QUANTITY_UNIT_CONTAINER = 'CONTAINER';
export const ITEM_QUANTITY_TYPE_TRUCK_LOAD = 'TRUCK_LOAD';
// TEMPERATURE TYPES
export const ITEM_TEMPERATURE_TYPE_DRY = 'DRY';
export const ITEM_TEMPERATURE_TYPE_HOT = 'HOT';
export const ITEM_TEMPERATURE_TYPE_FROZEN = 'FROZEN';
export const ITEM_TEMPERATURE_TYPE_CHILLED = 'CHILLED';
// ROLE/USER TYPES
export const USER_ROLE_TYPE_DRIVER = 'DRIVER';
export const USER_ROLE_TYPE_GENERAL = 'GENERAL';
export const USER_ROLE_TYPE_CARRIER = 'CARRIER';
export const USER_ROLE_TYPE_CUSTOMER = 'CUSTOMER';
// PAYMENT TERMS OPTIONS
export const PAYMENT_TERM_OPTION_PREPAID = 'PREPAID';
export const PAYMENT_TERM_OPTION_COLLECT = 'COLLECT';
export const PAYMENT_TERM_OPTION_QUICK_PAY = 'QUICK_PAY';
export const PAYMENT_TERM_OPTION_DIRECT_PAY = 'DIRECT_PAY';
export const PAYMENT_TERM_OPTION_THIRD_PARTY = 'THIRD_PARTY';
export const PAYMENT_TERM_OPTION_OUTBOUND_COLLECT = 'OUTBOUND_COLLECT';
export const PAYMENT_TERM_OPTION_FACTORING_COMPANY = 'FACTORING_COMPANY';
export const PAYMENT_TERM_OPTION_INTERNAL_FACTORING = 'INTERNAL_FACTORING';
export const PAYMENT_TERM_OPTION_THIRD_PARTY_PREPAID = 'THIRD_PARTY_PREPAID';
export const PAYMENT_TERM_OPTION_THIRD_PARTY_COLLECT = 'THIRD_PARTY_COLLECT';
// CARRIER TYPES
export const CARRIER_TYPE_OTHER = 'OTHER';
export const CARRIER_TYPE_BROKER = 'BROKER';
export const CARRIER_TYPE_INTRASTATE = 'INTRASTATE';
export const CARRIER_TYPE_INTERSTATE = 'INTERSTATE';
export const CARRIER_TYPE_INTERSTATE_OR_BROKER = 'INTERSTATE_OR_BROKER';
// TEL DEFAULT BRANCH
export const TEL_DEFAULT_BRANCH_TYPE_USER_BRANCH = 'USER_BRANCH';
export const TEL_DEFAULT_BRANCH_TYPE_CURRENT_BRANCH = 'CURRENT_BRANCH';
export const TEL_DEFAULT_BRANCH_TYPE_CUSTOMER_BRANCH = 'CUSTOMER_BRANCH';
// ACCESSORIAL DISTRICT TYPES
export const ACCESSORIAL_DISTRICT_TYPE_US = 'US';
export const ACCESSORIAL_DISTRICT_TYPE_MIDWEST = 'MIDWEST';
export const ACCESSORIAL_DISTRICT_TYPE_EAST_COST = 'EAST_COST';
export const ACCESSORIAL_DISTRICT_TYPE_GULF_COAST = 'GULF_COAST';
export const ACCESSORIAL_DISTRICT_TYPE_WEST_COAST = 'WEST_COAST';
export const ACCESSORIAL_DISTRICT_TYPE_CALIFORNIA = 'CALIFORNIA';
export const ACCESSORIAL_DISTRICT_TYPE_NEW_ENGLAND = 'NEW_ENGLAND';
export const ACCESSORIAL_DISTRICT_TYPE_ROCKY_MOUNTAIN = 'ROCKY_MOUNTAIN';
export const ACCESSORIAL_DISTRICT_TYPE_LOWER_ATLANTIC = 'LOWER_ATLANTIC';
export const ACCESSORIAL_DISTRICT_TYPE_CENTRAL_ATLANTIC = 'CENTRAL_ATLANTIC';
export const ACCESSORIAL_DISTRICT_TYPE_WEST_COAST_EXCEPT_CALIFORNIA = 'WEST_COAST_EXCEPT_CALIFORNIA';
// SAFETY TYPES
export const SAFETY_TYPE_MODERATE = 'MODERATE';
export const SAFETY_TYPE_ACCEPTABLE = 'ACCEPTABLE';
export const SAFETY_TYPE_UNACCEPTABLE = 'UNACCEPTABLE';
// SAFETY RATING TYPES
export const SAFETY_RATING_TYPE_NOT_RATED = 'NOT_RATED';
export const SAFETY_RATING_TYPE_CONDITIONAL = 'CONDITIONAL';
export const SAFETY_RATING_TYPE_SATISFACTORY = 'SATISFACTORY';
export const SAFETY_RATING_TYPE_UNSATISFACTORY = 'UNSATISFACTORY';
// SAFETY AUTHORITY TYPES
export const SAFETY_AUTHORITY_TYPE_NONE = 'NONE';
export const SAFETY_AUTHORITY_TYPE_ACTIVE = 'ACTIVE';
export const SAFETY_AUTHORITY_TYPE_INACTIVE = 'INACTIVE';
// IMPORT MAPPERS
export const IMPORT_MAPPER_CLO_TYPE_JB_HUNT = 'JB_HUNT';
// AUDIT TYPES
export const AUDIT_TYPE_USER = 'USER';
export const AUDIT_TYPE_ROLE = 'ROLE';
export const AUDIT_TYPE_LOAD = 'LOAD';
export const AUDIT_TYPE_TASK = 'TASK';
export const AUDIT_TYPE_TRUCK = 'TRUCK';
export const AUDIT_TYPE_DRIVER = 'DRIVER';
export const AUDIT_TYPE_REPORT = 'REPORT';
export const AUDIT_TYPE_CARRIER = 'CARRIER';
export const AUDIT_TYPE_TRAILER = 'TRAILER';
export const AUDIT_TYPE_PAYROLL = 'PAYROLL';
export const AUDIT_TYPE_BRANCH = 'ENTERPRISE';
export const AUDIT_TYPE_TEMPLATES = 'TEMPLATES';
export const AUDIT_TYPE_WORK_ORDER = 'WORK_ORDER';
export const AUDIT_TYPE_REFERENCE_TYPE = 'REFERENCE_TYPE';
export const AUDIT_TYPE_MASTER_INVOICE = 'MASTER_INVOICE';
export const AUDIT_TYPE_VENDOR_PAYROLL = 'VENDOR_PAYROLL';
export const AUDIT_TYPE_BRANCH_CONFIG = 'ENTERPRISE_CONFIG';
export const AUDIT_TYPE_DRIVER_LATEST_LOCATION = 'DRIVER_LATEST_LOCATION';
// FACTORING COMPANY TYPES
export const FACTORING_COMPANY_TYPE_TRIUMPH = 'TRIUMPH';
export const FACTORING_COMPANY_TYPE_COMPASS = 'COMPASS';
export const FACTORING_COMPANY_TYPE_E_CAPITAL = 'E_CAPITAL';
export const FACTORING_COMPANY_TYPE_OTR_CAPITAL = 'OTR_CAPITAL';
export const FACTORING_COMPANY_TYPE_RTS_FINANCIAL = 'RTS_FINANCIAL';
// TRIUMPH FACTORING PAYMENT TERMS
export const FACTORING_PAYMENT_TERM_TYPE_TRIUMPH_STANDARD_PAY = 'TRIUMPH_STANDARD_PAY';
export const FACTORING_PAYMENT_TERM_TYPE_TRIUMPH_BROKER_QUICK_PAY = 'TRIUMPH_BROKER_QUICK_PAY';
export const FACTORING_PAYMENT_TERM_TYPE_TRIUMPH_CARRIER_QUICK_PAY = 'TRIUMPH_CARRIER_QUICK_PAY';
// FUEL CARDS TYPES
export const FUEL_CARDS_TYPE_EFS = 'EFS';
export const FUEL_CARDS_TYPE_BVD = 'BVD';
export const FUEL_CARDS_TYPE_QUIKQ = 'QUIKQ';
export const FUEL_CARDS_TYPE_RELAY = 'RELAY';
export const FUEL_CARDS_TYPE_COMDATA = 'COMDATA';
export const FUEL_CARDS_TYPE_SELF_FUELED = 'SELF_FUELED';
// CARRIER RATE INTEGRATION TYPES
export const CARRIER_RATE_INTEGRATION_TYPE_UPS = 'UPS';
export const CARRIER_RATE_INTEGRATION_TYPE_YRC = 'YRC';
export const CARRIER_RATE_INTEGRATION_TYPE_GLS = 'GLS';
export const CARRIER_RATE_INTEGRATION_TYPE_RIST = 'RIST';
export const CARRIER_RATE_INTEGRATION_TYPE_SAIA = 'SAIA';
export const CARRIER_RATE_INTEGRATION_TYPE_WARD = 'WARD';
export const CARRIER_RATE_INTEGRATION_TYPE_ODFL = 'ODFL';
export const CARRIER_RATE_INTEGRATION_TYPE_SEFL = 'SEFL';
export const CARRIER_RATE_INTEGRATION_TYPE_SMTL = 'SMTL';
export const CARRIER_RATE_INTEGRATION_TYPE_FEDEX = 'FEDEX';
export const CARRIER_RATE_INTEGRATION_TYPE_POLARIS = 'POLARIS';
export const CARRIER_RATE_INTEGRATION_TYPE_HOLLAND = 'HOLLAND';
export const CARRIER_RATE_INTEGRATION_TYPE_REDDAWAY = 'REDDAWAY';
export const CARRIER_RATE_INTEGRATION_TYPE_NEW_PENN = 'NEW_PENN';
export const CARRIER_RATE_INTEGRATION_TYPE_ARC_BEST = 'ARC_BEST';
export const CARRIER_RATE_INTEGRATION_TYPE_DAY_AND_ROSS = 'DAYROSS';
export const CARRIER_RATE_INTEGRATION_TYPE_MANITOULIN = 'MANITOULIN';
export const CARRIER_RATE_INTEGRATION_TYPE_AAA_COOPER = 'AAA_COOPER';
export const CARRIER_RATE_INTEGRATION_TYPE_ROADRUNNER = 'ROADRUNNER';
export const CARRIER_RATE_INTEGRATION_TYPE_UNISHIPPERS = 'UNISHIPPERS';
export const CARRIER_RATE_INTEGRATION_TYPE_A_DUIE_PYLE = 'A_DUIE_PYLE';
export const CARRIER_RATE_INTEGRATION_TYPE_PRIORITY_ONE = 'PRIORITY_ONE';
export const CARRIER_RATE_INTEGRATION_TYPE_XPO_LOGISTICS = 'XPO_LOGISTICS';
export const CARRIER_RATE_INTEGRATION_TYPE_DAYTON_FREIGHT = 'DAYTON_FREIGHT';
// USER MONITORING INTEGRATION TYPES
export const USER_MONITORING_APP_INTEGRATION_TYPE_SMARTLOOK = 'SMARTLOOK';
// CARRIER INTEGRATION LABEL TYPES
export const CARRIER_INTEGRATION_LABEL_TYPE_NONE = 'NONE';
export const CARRIER_INTEGRATION_LABEL_TYPE_STANDARD_LAYOUT_1X2 = 'STANDARD_LAYOUT_1X2';
export const CARRIER_INTEGRATION_LABEL_TYPE_STANDARD_LAYOUT_4X6 = 'STANDARD_LAYOUT_4X6';
export const CARRIER_INTEGRATION_LABEL_TYPE_STANDARD_LAYOUT_8X11 = 'STANDARD_LAYOUT_8X11';
export const CARRIER_INTEGRATION_LABEL_TYPE_STANDARD_LAYOUT_LETTER = 'STANDARD_LAYOUT_LETTER';
export const CARRIER_INTEGRATION_LABEL_TYPE_STANDARD_LAYOUT_AVERY_5264 = 'STANDARD_LAYOUT_AVERY_5264';
// CARRIER SYNCHRONIZATION TYPES
export const CARRIER_SYNCHRONIZATION_TYPE_RMIS = 'RMIS';
export const CARRIER_SYNCHRONIZATION_TYPE_SAFER_WATCH = 'SAFER_WATCH';
export const CARRIER_SYNCHRONIZATION_TYPE_MY_CARRIER_PACKETS = 'MY_CARRIER_PACKETS';
// ADVANCE PAYMENT INTEGRATION TYPES
export const ADVANCE_PAYMENT_INTEGRATION_TYPE_COMDATA = 'COMDATA';
// ADDITIONAL FILTER TYPES
export const ADDITIONAL_FILTER_TYPE_TRUCK = 'TRUCK';
export const ADDITIONAL_FILTER_TYPE_BRANCH = 'BRANCH';
export const ADDITIONAL_FILTER_TYPE_DRIVER = 'DRIVER';
export const ADDITIONAL_FILTER_TYPE_CARRIER = 'CARRIER';
export const ADDITIONAL_FILTER_TYPE_ASSIGNEE = 'ASSIGNEE';
export const ADDITIONAL_FILTER_TYPE_BILL_TO_NAME = 'BILL_TO_NAME';
export const ADDITIONAL_FILTER_TYPE_INVOICE_NUMBER = 'INVOICE_NUMBER';
export const ADDITIONAL_FILTER_TYPE_CLO_PRIMARY_REFERENCE_VALUE = 'CLO_PRIMARY_REFERENCE_VALUE';
// BRANCH FILTER TYPES
export const BRANCH_FILTER_TYPE_TREE = 'TREE';
export const BRANCH_FILTER_TYPE_FULL_TREE = 'FULL_TREE';
export const BRANCH_FILTER_TYPE_PARENT_AND_CURRENT = 'PARENT_AND_CURRENT';
// IFTA STATUS TYPES
export const IFTA_STATUS_TYPE_FAILED = 'FAILED';
export const IFTA_STATUS_TYPE_SUCCESS = 'SUCCESS';
// DRIVER TYPE
export const DRIVER_TYPE_EMPLOYEE = 'EMPLOYEE';
export const DRIVER_TYPE_CONTRACTOR = 'CONTRACTOR';
export const DRIVER_TYPE_OWNER_OPERATOR = 'OWNER_OPERATOR';
// LIST ISSUE TYPE
export const LIST_ISSUE_TYPE_VISA_EXPIRATION = 'VISA_EXPIRATION';
export const LIST_ISSUE_TYPE_DOCUMENT_MISSING = 'DOCUMENT_MISSING';
export const LIST_ISSUE_TYPE_LICENSE_EXPIRATION = 'LICENSE_EXPIRATION';
export const LIST_ISSUE_TYPE_PASSPORT_EXPIRATION = 'PASSPORT_EXPIRATION';
export const LIST_ISSUE_TYPE_DOCUMENT_EXPIRATION = 'DOCUMENT_EXPIRATION';
export const LIST_ISSUE_TYPE_CONTRACT_EXPIRATION = 'CONTRACT_EXPIRATION';
export const LIST_ISSUE_TYPE_INSURANCE_EXPIRATION = 'INSURANCE_EXPIRATION';
export const LIST_ISSUE_TYPE_GREEN_CARD_EXPIRATION = 'GREEN_CARD_EXPIRATION';
export const LIST_ISSUE_TYPE_CERTIFICATE_EXPIRATION = 'CERTIFICATE_EXPIRATION';
export const LIST_ISSUE_TYPE_TERMINATION_EXPIRATION = 'TERMINATION_EXPIRATION';
export const LIST_ISSUE_TYPE_REGISTRATION_EXPIRATION = 'REGISTRATION_EXPIRATION';
export const LIST_ISSUE_TYPE_COMPENSATION_EXPIRATION = 'COMPENSATION_EXPIRATION';
export const LIST_ISSUE_TYPE_EQUIPMENT_SERVICE_EXPIRATION = 'EQUIPMENT_SERVICE_EXPIRATION';
export const LIST_ISSUE_TYPE_WORK_AUTHENTICATION_EXPIRATION = 'WORK_AUTHENTICATION_EXPIRATION';
export const LIST_ISSUE_TYPE_EQUIPMENT_COMPONENT_EXPIRATION = 'EQUIPMENT_COMPONENT_EXPIRATION';
// INVOICE TYPE
export const INVOICE_TYPE_FLEET_INVOICE = 'FLEET_INVOICE';
export const INVOICE_TYPE_CARRIER_INVOICE = 'CARRIER_INVOICE';
export const INVOICE_TYPE_CUSTOMER_INVOICE = 'CUSTOMER_INVOICE';
export const INVOICE_TYPE_FLEET_VENDOR_INVOICE = 'FLEET_VENDOR_INVOICE';
export const INVOICE_TYPE_SERVICE_VENDOR_INVOICE = 'SERVICE_VENDOR_INVOICE';
// VENDOR PAYROLL ACCESSORIAL PAY FOR UNIT TYPES
export const VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_NONE = 'NONE';
export const VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_EVERY_TRUCK = 'EVERY_TRUCK';
export const VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_EVERY_DRIVER = 'EVERY_DRIVER';
export const VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_TRUCKS = 'SELECTED_TRUCKS';
export const VENDOR_PAYROLL_ACCESSORIAL_PAY_FOR_UNIT_TYPE_SELECTED_DRIVERS = 'SELECTED_DRIVER';
// DRIVER DUTY STATUS CODE TYPE
export const DUTY_STATUS_CODE_TYPE_UNKNOWN = 'UNKNOWN';
export const DUTY_STATUS_CODE_TYPE_DRIVING = 'DRIVING';
export const DUTY_STATUS_CODE_TYPE_ON_DUTY = 'ON_DUTY';
export const DUTY_STATUS_CODE_TYPE_SLEEPER = 'SLEEPER';
export const DUTY_STATUS_CODE_TYPE_OFF_DUTY = 'OFF_DUTY';
export const DUTY_STATUS_CODE_TYPE_YARD_MOVE = 'YARD_MOVE';
export const DUTY_STATUS_CODE_TYPE_SLEEPER_BED = 'SLEEPER_BED';
export const DUTY_STATUS_CODE_TYPE_WAITING_TIME = 'WAITING_TIME';
export const DUTY_STATUS_CODE_TYPE_INTERMEDIATE = 'INTERMEDIATE';
export const DUTY_STATUS_CODE_TYPE_PERSONAL_CONVEYANCE = 'PERSONAL_CONVEYANCE';
// START POINT LOCATION SOURCE TYPES
export const START_POINT_SOURCE_TYPE_TEL = 'TEL';
export const START_POINT_SOURCE_TYPE_MANUAL = 'MANUAL';
export const START_POINT_SOURCE_TYPE_LNL = 'LAST_KNOWN_LOCATION';
// DISPATCH_INTEGRATION_TYPES
export const DISPATCH_INTEGRATION_TYPE_FOUR_KITES = 'FOUR_KITES';
export const DISPATCH_INTEGRATION_TYPE_MACRO_POINT = 'MACRO_POINT';
export const DISPATCH_INTEGRATION_TYPE_TRUCKER_TOOLS = 'TRUCKER_TOOLS';
// EVENT INTEGRATION TYPES
export const TERMINAL_INTEGRATION_TYPE_VERACORE = 'VERACORE';
// STATUS_CODE_MAPPING_INTEGRATION_TYPES
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_EDI = 'EDI';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_GLS = 'GLS';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_YRC = 'YRC';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_UPS = 'UPS';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_SEFL = 'SEFL';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_SAIA = 'SAIA';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_RIST = 'RIST';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_ODFL = 'ODFL';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_SMTL = 'SMTL';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_WARD = 'WARD';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_FEDEX = 'FEDEX';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_POLARIS = 'POLARIS';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_ARC_BEST = 'ARC_BEST';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_NEW_PENN = 'NEW_PENN';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_DAY_AND_ROSS = 'DAYROSS';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_PROJECT_44 = 'PROJECT_44';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_FOUR_KITES = 'FOUR_KITES';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_ROADRUNNER = 'ROADRUNNER';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_MANITOULIN = 'MANITOULIN';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_AAA_COOPER = 'AAA_COOPER';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_A_DUIE_PYLE = 'A_DUIE_PYLE';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_MACRO_POINT = 'MACRO_POINT';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_CH_ROBINSON = 'CH_ROBINSON';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_XPO_LOGISTICS = 'XPO_LOGISTICS';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_TRUCKER_TOOLS = 'TRUCKER_TOOLS';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_DAYTON_FREIGHT = 'DAYTON_FREIGHT';
export const STATUS_CODE_MAPPING_INTEGRATION_TYPE_HOLLAND_AND_REDDAWAY = 'HOLLAND_AND_REDDAWAY';
// MACRO POINT STATUS CODE TYPES
export const MACRO_POINT_STATUS_CODE_TYPE_ARRIVED_PICKUP = 'X3';
export const MACRO_POINT_STATUS_CODE_TYPE_DEPARTED_PICKUP = 'AF';
export const MACRO_POINT_STATUS_CODE_TYPE_ARRIVED_DROP_OFF = 'X1';
export const MACRO_POINT_STATUS_CODE_TYPE_DEPARTED_DROP_OFF = 'D1';
// MACRO POINT STATUS CODE STATUS TYPES
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_SUCCESS = 'SUCCESS';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_FAILURE = 'FAILURE';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_TRACKING_NOW = 'TRACKING_NOW';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_READY_TO_TRACK = 'READY_TO_TRACK';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_DENIED_BY_DRIVER = 'DENIED_BY_DRIVER';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_REPORTED_LANDLINE = 'REPORTED_LANDLINE';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_STOPPED_BY_CREATOR = 'STOPPED_BY_CREATOR';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_PHONE_NOT_LOCATABLE = 'PHONE_NOT_LOCATABLE';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_INVALID_TRUCK_NUMBER = 'INVALID_TRUCK_NUMBER';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_REFUSED_INSTALLATION = 'REFUSED_INSTALLATION';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_REPORTED_WRONG_NUMBER = 'REPORTED_WRONG_NUMBER';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_INVALID_TRAILER_NUMBER = 'INVALID_TRAILER_NUMBER';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_REQUESTING_APP_INSTALL = 'REQUESTING_APP_INSTALL';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_REQUESTING_INSTALLATION = 'REQUESTING_INSTALLATION';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_STOPPED_EARLY_BY_DRIVER = 'STOPPED_EARLY_BY_DRIVER';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_EXPIRED_WITHOUT_LOCATION = 'EXPIRED_WITHOUT_LOCATION';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_LOCATION_HIDDEN_BY_DRIVER = 'LOCATION_HIDDEN_BY_DRIVER';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_DRIVER_PHONE_UNRESPONSIVE = 'DRIVER_PHONE_UNRESPONSIVE';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_TRACKING_WAITING_FOR_UPDATE = 'TRACKING_WAITING_FOR_UPDATE';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_EXPIRED_WITHOUT_INSTALLATION = 'EXPIRED_WITHOUT_INSTALLATION';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_TRACKING_COMPLETE_SUCCESSFULLY = 'TRACKING_COMPLETE_SUCCESSFULLY';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_EXPIRED_WITH_LOCATION_HIDDEN_BY_DRIVER =
  'EXPIRED_WITH_LOCATION_HIDDEN_BY_DRIVER';
export const MACRO_POINT_STATUS_CODE_STATUS_TYPE_EXPIRED_WITH_DRIVER_PHONE_UNRESPONSIVE =
  'EXPIRED_WITH_DRIVER_PHONE_UNRESPONSIVE';
// EDI INTEGRATION EXTRACTOR/EXPORTER PROTOCOL TYPES
export const EDI_INTEGRATION_PROTOCOL_TYPE_FTP = 'FTP';
export const EDI_INTEGRATION_PROTOCOL_TYPE_SFTP = 'SFTP';
// terminal process types
export const TERMINAL_PROCESS_TYPE_API = 'API';
export const TERMINAL_PROCESS_TYPE_EDI = 'EDI';
export const TERMINAL_PROCESS_TYPE_EMAIL = 'EMAIL';
export const TERMINAL_PROCESS_TYPE_INTERNAL = 'INTERNAL';
// invoice scopes
export const INVOICE_SCOPE_TYPE_DRIVER = 'DRIVER';
export const INVOICE_SCOPE_TYPE_VENDOR = 'VENDOR';
export const INVOICE_SCOPE_TYPE_CARRIER = 'CARRIER';
export const INVOICE_SCOPE_TYPE_CUSTOMER = 'CUSTOMER';
export const INVOICE_SCOPE_TYPE_SERVICE_VENDOR = 'SERVICE_VENDOR';
// invoice mapping types
export const INVOICE_MAPPING_TYPE_FUEL = 'FUEL';
export const INVOICE_MAPPING_TYPE_INVOICE = 'INVOICE';
export const INVOICE_MAPPING_TYPE_DISCOUNT = 'DISCOUNT';
export const INVOICE_MAPPING_TYPE_LINE_HAUL = 'LINE_HAUL';
export const INVOICE_MAPPING_TYPE_ASSESSORIALS = 'ASSESSORIALS';
export const INVOICE_MAPPING_TYPE_CUSTOMER_RECEIVABLE = 'CUSTOMER_RECEIVABLE';
// qb account mapping account types
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_TOLL = 'TOLL';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_FUEL_CARD = 'FUEL_CARD';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_INVOICE_CHARGE = 'INVOICE_CHARGE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DRIVER_PAYABLE = 'DRIVER_PAYABLE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_VENDOR_PAYABLE = 'VENDOR_PAYABLE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CARRIER_PAYABLE = 'CARRIER_PAYABLE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ADVANCED_PAYMENT = 'ADVANCED_PAYMENT';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DISCOUNT = INVOICE_MAPPING_TYPE_DISCOUNT;
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CONTRACTOR_PAYABLE = 'CONTRACTOR_PAYABLE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_LINE_HAUL = INVOICE_MAPPING_TYPE_LINE_HAUL;
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CUSTOMER_RECEIVABLE = 'CUSTOMER_RECEIVABLE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_ASSESSORIALS = INVOICE_MAPPING_TYPE_ASSESSORIALS;
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_OWNER_OPERATOR_PAYABLE = 'OWNER_OPERATOR_PAYABLE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_SERVICE_VENDOR_PAYABLE = 'SERVICE_VENDOR_PAYABLE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_VENDOR_EXPENSE_DISTRIBUTION_LINE = 'VENDOR_EXPENSE_DISTRIBUTION_LINE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_DRIVER_EXPENSE_DISTRIBUTION_LINE = 'DRIVER_EXPENSE_DISTRIBUTION_LINE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CUSTOMER_INCOME_DISTRIBUTION_LINE = 'CUSTOMER_INCOME_DISTRIBUTION_LINE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CARRIER_EXPENSE_DISTRIBUTION_LINE = 'CARRIER_EXPENSE_DISTRIBUTION_LINE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_CONTRACTOR_EXPENSE_DISTRIBUTION_LINE =
  'CONTRACTOR_EXPENSE_DISTRIBUTION_LINE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_OWNER_OPERATOR_EXPENSE_DISTRIBUTION_LINE =
  'OWNER_OPERATOR_EXPENSE_DISTRIBUTION_LINE';
export const QB_ACCOUNT_MAPPING_ACCOUNT_TYPE_SERVICE_VENDOR_EXPENSE_DISTRIBUTION_LINE =
  'SERVICE_VENDOR_EXPENSE_DISTRIBUTION_LINE';
// create do source types
export const CREATE_DO_SOURCE_TYPE_API = 'API';
export const CREATE_DO_SOURCE_TYPE_EDI = 'EDI';
export const CREATE_DO_SOURCE_TYPE_COPY = 'COPY';
export const CREATE_DO_SOURCE_TYPE_QUOTE = 'QUOTE';
export const CREATE_DO_SOURCE_TYPE_MANUAL = 'MANUAL';
export const CREATE_DO_SOURCE_TYPE_TEMPLATE = 'TEMPLATE';
export const CREATE_DO_SOURCE_TYPE_ORDER_QUOTE = 'ORDER_QUOTE';
// carrier accept rate required fields
export const CARRIER_ACCEPT_RATE_REQUIRED_FIELD_APPROVED_BY = 'APPROVED_BY';
export const CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_NAME = 'DRIVER_NAME';
export const CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRUCK_NUMBER = 'TRUCK_NUMBER';
export const CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRAILER_NUMBER = 'TRAILER_NUMBER';
export const CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_EMAIL = 'DISPATCHER_EMAIL';
export const CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_FULL_NAME = 'DRIVER_FULL_NAME';
export const CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_PHONE_NUMBER = 'DRIVER_PHONE_NUMBER';
export const CARRIER_ACCEPT_RATE_REQUIRED_FIELD_INTERNAL_LOAD_NUMBER = 'INTERNAL_LOAD_NUMBER';
export const CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_FULL_NAME = 'DISPATCHER_FULL_NAME';
export const CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_PHONE_NUMBER = 'DISPATCHER_PHONE_NUMBER';
// carrier accept rate show fields
export const CARRIER_ACCEPT_RATE_SHOW_FIELD_RATE = 'RATE';
export const CARRIER_ACCEPT_RATE_SHOW_FIELD_BILL_TO = 'BILL_TO';
export const CARRIER_ACCEPT_RATE_SHOW_FIELD_SERVICES = 'SERVICES';
export const CARRIER_ACCEPT_RATE_SHOW_FIELD_EQUIPMENT = 'EQUIPMENT';
export const CARRIER_ACCEPT_RATE_SHOW_FIELD_INSTRUCTION = 'INSTRUCTION';
// currency types
export const CURRENCY_TYPE_EUR = 'EUR';
export const CURRENCY_TYPE_CAD = 'CAD';
export const CURRENCY_TYPE_USD = 'USD';
export const CURRENCY_TYPE_MXN = 'MXN';
// integration mail config types
export const INTEGRATION_MAIL_CONFIG_TYPE_GMAIL = 'GMAIL';
export const INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM = 'CUSTOM';
export const INTEGRATION_MAIL_CONFIG_TYPE_OUTLOOK = 'OUTLOOK';
// document type mapping types
export const DOCUMENT_TYPE_MAPPING_TYPE_SAIA = 'SAIA';
export const DOCUMENT_TYPE_MAPPING_TYPE_HUB_TRAN = 'HUB_TRAN';
// accounting integration types
export const ACCOUNTING_INTEGRATION_TYPE_HUB_TRAN = 'HUB_TRAN';
export const ACCOUNTING_INTEGRATION_TYPE_QUICK_BOOKS = 'QUICK_BOOKS';
export const ACCOUNTING_INTEGRATION_TYPE_SAGE_INTACCT = 'SAGE_INTACCT';
export const ACCOUNTING_INTEGRATION_TYPE_BUSINESS_CENTRAL = 'BUSINESS_CENTRAL';
// commission assignment
export const COMMISSION_ASSIGNMENT_TYPE_SALE_PERSON = 'SALE_PERSON';
export const COMMISSION_ASSIGNMENT_TYPE_BROKER_AGENT = 'BROKER_AGENT';
export const COMMISSION_ASSIGNMENT_TYPE_ACCOUNT_MANAGER = 'ACCOUNT_MANAGER';
export const COMMISSION_ASSIGNMENT_TYPE_SALE_REPRESENTATIVE = 'SALE_REPRESENTATIVE';
export const COMMISSION_ASSIGNMENT_TYPE_CARRIER_REPRESENTATIVE = 'CARRIER_REPRESENTATIVE';
// invoice status rule condition types
export const INVOICE_STATUS_RULE_CONDITION_TYPE_LOAD_STATUS = 'LOAD_STATUS';
export const INVOICE_STATUS_RULE_CONDITION_TYPE_ADDITIONAL_INVOICE = 'ADDITIONAL_INVOICE';
export const INVOICE_STATUS_RULE_CONDITION_TYPE_RATE_TOTAL_MISMATCH = 'RATE_TOTAL_MISMATCH';
export const INVOICE_STATUS_RULE_CONDITION_TYPE_TRANSPORTATION_MODE = 'TRANSPORTATION_MODE';
// invoice status rule rate mismatch types
export const INVOICE_STATUS_RULE_RATE_MISMATCH_TYPE_FLAT = 'FLAT';
export const INVOICE_STATUS_RULE_RATE_MISMATCH_TYPE_PERCENT = 'PERCENT';
// default login screen types
export const DEFAULT_LOGIN_SCREEN_TYPE_TRIP_LIST = 'TRIP_LIST';
export const DEFAULT_LOGIN_SCREEN_TYPE_ORDER_LIST = 'ORDER_LIST';
export const DEFAULT_LOGIN_SCREEN_TYPE_TRUCK_LIST = 'TRUCK_LIST';
export const DEFAULT_LOGIN_SCREEN_TYPE_ROUTE_TRIP = 'ROUTE_TRIP';
export const DEFAULT_LOGIN_SCREEN_TYPE_DRIVER_LIST = 'DRIVER_LIST';
export const DEFAULT_LOGIN_SCREEN_TYPE_VENDOR_LIST = 'VENDOR_LIST';
export const DEFAULT_LOGIN_SCREEN_TYPE_ROUTE_ORDER = 'ROUTE_ORDER';
export const DEFAULT_LOGIN_SCREEN_TYPE_CARRIER_LIST = 'CARRIER_LIST';
export const DEFAULT_LOGIN_SCREEN_TYPE_PAYROLL_LIST = 'PAYROLL_LIST';
export const DEFAULT_LOGIN_SCREEN_TYPE_TRAILER_LIST = 'TRAILER_LIST';
export const DEFAULT_LOGIN_SCREEN_TYPE_DRIVER_CARDS = 'DRIVER_CARDS';
export const DEFAULT_LOGIN_SCREEN_TYPE_CUSTOMER_PORTAL = 'CUSTOMER_PORTAL';
export const DEFAULT_LOGIN_SCREEN_TYPE_AVAILABLE_DRIVERS = 'AVAILABLE_DRIVERS';
export const DEFAULT_LOGIN_SCREEN_TYPE_DRIVER_INVOICE_LIST = 'DRIVER_INVOICE_LIST';
export const DEFAULT_LOGIN_SCREEN_TYPE_VENDOR_INVOICE_LIST = 'VENDOR_INVOICE_LIST';
export const DEFAULT_LOGIN_SCREEN_TYPE_CARRIER_INVOICE_LIST = 'CARRIER_INVOICE_LIST';
export const DEFAULT_LOGIN_SCREEN_TYPE_CUSTOMER_INVOICE_LIST = 'CUSTOMER_INVOICE_LIST';
export const DEFAULT_LOGIN_SCREEN_TYPE_CUSTOMER_MASTER_INVOICE_LIST = 'CUSTOMER_MASTER_INVOICE_LIST';
// item types
export const ITEM_TYPE_GENERAL = 'GENERAL';
export const ITEM_TYPE_VEHICLE = 'VEHICLE';
// transportation mode types
export const TRANSPORTATION_MODE_TYPE_LTL = 'LTL';
export const TRANSPORTATION_MODE_TYPE_AIR = 'AIR';
export const TRANSPORTATION_MODE_TYPE_RAIL = 'RAIL';
export const TRANSPORTATION_MODE_TYPE_OCEAN = 'OCEAN';
export const TRANSPORTATION_MODE_TYPE_DRAYAGE = 'DRAYAGE';
export const TRANSPORTATION_MODE_TYPE_TRUCK_LOAD = 'TRUCK_LOAD';
export const TRANSPORTATION_MODE_TYPE_SMALL_TRUCK = 'SMALL_TRUCK';
export const TRANSPORTATION_MODE_TYPE_BOX_SHIPMENTS = 'BOX_SHIPMENTS';
// global search types
export const GLOBAL_SEARCH_TYPE_ALL = 'ALL';
export const GLOBAL_SEARCH_TYPE_ITEM = 'ITEM';
export const GLOBAL_SEARCH_TYPE_EVENT = 'EVENT';
export const GLOBAL_SEARCH_TYPE_TRUCK = 'TRUCK';
export const GLOBAL_SEARCH_TYPE_DRIVER = 'DRIVER';
export const GLOBAL_SEARCH_TYPE_BRANCH = 'BRANCH';
export const GLOBAL_SEARCH_TYPE_BILL_TO = 'BILL_TO';
export const GLOBAL_SEARCH_TYPE_CARRIER = 'CARRIER';
export const GLOBAL_SEARCH_TYPE_TRAILER = 'TRAILER';
export const GLOBAL_SEARCH_TYPE_REFERENCE = 'REFERENCE';
// rate charge type
export const RATE_CHARGE_TYPE_CARRIER = 'CARRIER';
export const RATE_CHARGE_TYPE_FLEET_VENDOR = 'FLEET_VENDOR';
export const RATE_CHARGE_TYPE_PRIMARY_DRIVER = 'PRIMARY_DRIVER';
export const RATE_CHARGE_TYPE_SECONDARY_DRIVER = 'SECONDARY_DRIVER';
// discount
export const DISCOUNT_TYPE_FLAT = 'FLAT';
export const VARIABLE_TYPE_WEIGHT = 'WEIGHT';
export const DISCOUNT_TYPE_VARIABLE = 'VARIABLE';
export const VARIABLE_TYPE_DISTANCE = 'DISTANCE';
// customer edi exporter transaction types
export const CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ORDER = 'ORDER';
export const CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_INVOICE = 'INVOICE';
export const CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_STATUS_MESSAGE = 'STATUS_MESSAGE';
export const CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ACCEPT_DECLINE_RESPONSE = 'ACCEPT_DECLINE_RESPONSE';
export const CUSTOMER_EDI_EXPORTER_TRANSACTION_TYPE_ACKNOWLEDGEMENT_RESPONSE = 'ACKNOWLEDGEMENT_RESPONSE';
// customer edi exporter receiver qualifier types
export const CUSTOMER_EDI_EXPORTER_RECEIVER_QUALIFIER_TYPE_SCAC = 'SCAC';
export const CUSTOMER_EDI_EXPORTER_RECEIVER_QUALIFIER_TYPE_PHONE = 'PHONE';
export const CUSTOMER_EDI_EXPORTER_RECEIVER_QUALIFIER_TYPE_GENERAL = 'GENERAL';
export const CUSTOMER_EDI_EXPORTER_RECEIVER_QUALIFIER_TYPE_DUNS_NUMBER = 'DUNS_NUMBER';
export const CUSTOMER_EDI_EXPORTER_RECEIVER_QUALIFIER_TYPE_DUNS_NUMBER_PLUS_SUFFIX = 'DUNS_NUMBER_PLUS_SUFFIX';
// shared accessorial auto apply to type
export const SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_FLEET = 'FLEET';
export const SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_CARRIER = 'CARRIER';
export const SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_CUSTOMER = 'CUSTOMER';
// vin audit configuration type
export const VIN_LOOKUP_INTEGRATION_TYPE_VIN_AUDIT = 'VIN_AUDIT';
// config fields
export const CONFIG_FIELD_CONTAINER = 'CONTAINER';
export const CONFIG_FIELD_REFERENCE = 'REFERENCE';
// freight class calculator
export const FREIGHT_CLASS_CALCULATOR_FAK = 'FAK';
export const FREIGHT_CLASS_CALCULATOR_STANDARD = 'STANDARD';
// factoring integration customer name types
export const FACTORING_COMPANY_CUSTOMER_NAME_TYPE_BRANCH_NAME = 'BRANCH_NAME';
export const FACTORING_COMPANY_CUSTOMER_NAME_TYPE_BILL_TO_NAME = 'BILL_TO_NAME';
// flexmonster pivot type
export const PIVOT_TYPE_NUMBER = 'number';
export const PIVOT_TYPE_YQMD = 'year/quarter/month/day';
// filter by division types
export const FILTER_BY_DIVISION_TYPE_NONE = 'NONE';
export const FILTER_BY_DIVISION_TYPE_TRIP_DIVISION = 'TRIP_DIVISION';
export const FILTER_BY_DIVISION_TYPE_ORIGINATED_DIVISION = 'ORIGINATED_DIVISION';
// custom excel export types
export const CUSTOM_EXCEL_EXPORT_TYPE_D_PLUS_S_DRIVER_DAILY_HOURS = 'D_PLUS_S_DRIVER_DAILY_HOURS';
// factoring export as types
export const FACTORING_EXPORT_AS_TYPE_BROKER = 'BROKER';
export const FACTORING_EXPORT_AS_TYPE_CARRIER = 'CARRIER';
// encoding types
export const ENCODING_TYPE_UTF_8 = 'UTF_8';
export const ENCODING_TYPE_ASCII = 'ASCII';
// tel source types
export const TEL_SOURCE_TYPE_MANUALLY = 'MANUALLY';
export const TEL_SOURCE_TYPE_TEMPLATE = 'TEMPLATE';
export const TEL_SOURCE_TYPE_LOAD_BOARD = 'LOAD_BOARD';
export const TEL_SOURCE_TYPE_EMAIL_QUOTE = 'EMAIL_QUOTE';
export const TEL_SOURCE_TYPE_RATE_ENGINE = 'RATE_ENGINE';
export const TEL_SOURCE_TYPE_LOAD_BOARD_QUOTE = 'LOAD_BOARD_QUOTE';
// smtp encryption types
export const SMTP_ENCRYPTION_TYPE_SSL = 'SSL';
export const SMTP_ENCRYPTION_TYPE_NONE = 'NONE';
export const SMTP_ENCRYPTION_TYPE_STARTTLS = 'STARTTLS';
// custom edi export mapper types
export const CUSTOM_EDI_EXPORT_MAPPER_TYPE_HUB_GROUP = 'HUB_GROUP';
export const CUSTOM_EDI_EXPORT_MAPPER_TYPE_APL_SUFFIX_MAPPER = 'APL_SUFFIX_MAPPER';
// service vendor types
export const SERVICE_VENDOR_TYPE_GENERAL = 'GENERAL';
export const SERVICE_VENDOR_TYPE_FLEET_SERVICE = 'FLEET_SERVICE';
export const SERVICE_VENDOR_TYPE_FLEET_SELF_SERVICE = 'FLEET_SELF_SERVICE';
// fuel cards product types
export const FUEL_CARDS_PRODUCT_TYPE_FUEL = 'FUEL';
export const FUEL_CARDS_PRODUCT_TYPE_REEFER = 'REEFER';
export const FUEL_CARDS_PRODUCT_TYPE_NON_FUEL = 'NON_FUEL';
export const FUEL_CARDS_PRODUCT_TYPE_DISCOUNT = 'DISCOUNT';
// edi exporter content format types
export const EDI_EXPORTER_CONTENT_FORMAT_TYPE_MULTILINE = 'MULTILINE';
export const EDI_EXPORTER_CONTENT_FORMAT_TYPE_SINGLE_LINE = 'SINGLE_LINE';
// trailer tracking integration types
export const TRAILER_TRACKING_INTEGRATION_TYPE_SKYBITZ = 'SKYBITZ';
// driver onboarding integration types
export const DRIVER_ONBOARDING_INTEGRATION_TYPE_TENSTREET = 'TENSTREET';
// preferred username format types
export const PREFERRED_USER_NAME_TYPE_EMAIL = 'EMAIL';
export const PREFERRED_USER_NAME_TYPE_FIRST_AND_LAST_NAME = 'FIRST_AND_LAST_NAME';
export const PREFERRED_USER_NAME_TYPE_FIRST_NAME_INITIAL_AND_LAST_NAME = 'FIRST_NAME_INITIAL_AND_LAST_NAME';
export const PREFERRED_USER_NAME_TYPE_FIRST_NAME_AND_LAST_NAME_INITIAL = 'FIRST_NAME_AND_LAST_NAME_INITIAL';
// documents types
export const DOCUMENTS_TYPE_DRIVER_PAYROLL = 'DRIVER_PAYROLL';
export const DOCUMENTS_TYPE_VENDOR_PAYROLL = 'VENDOR_PAYROLL';
export const DOCUMENTS_TYPE_MASTER_INVOICE = 'MASTER_INVOICE';
// log message type
export const LOG_MESSAGE_TYPE_DISTANCE_SERVICE_GEOFENCING_ADDRESS =
  'LOG_MESSAGE_TYPE_DISTANCE_SERVICE_GEOFENCING_ADDRESS';
export const LOG_MESSAGE_TYPE_GOOGLE_GEOCODE_API = 'GOOGLE_GEOCODE_API';
export const LOG_MESSAGE_TYPE_GOOGLE_MAPS_GEOCODER = 'GOOGLE_MAPS_GEOCODER';
// task management object types
export const TASK_MANAGEMENT_OBJECT_TYPE_TRUCK = 'TRUCK';
export const TASK_MANAGEMENT_OBJECT_TYPE_DRIVER = 'DRIVER';
export const TASK_MANAGEMENT_OBJECT_TYPE_GENERAL = 'GENERAL';
export const TASK_MANAGEMENT_OBJECT_TYPE_TRAILER = 'TRAILER';
export const TASK_MANAGEMENT_OBJECT_TYPE_FLEET_VENDOR = 'FLEET_VENDOR';
// task management sub object types
export const TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE = 'EQUIPMENT_SERVICE';
// document sending types
export const DOCUMENT_SENDING_TYPE_LINK = 'LINK';
export const DOCUMENT_SENDING_TYPE_ATTACHMENT = 'ATTACHMENT';
export const DOCUMENT_SENDING_TYPE_ATTACHMENT_AND_LINK = 'ATTACHMENT_AND_LINK';
// source types
export const SOURCE_TYPE_EDI = 'EDI';
export const SOURCE_TYPE_WEB_APP = 'WEB_APP';
export const SOURCE_TYPE_MOBILE_APP = 'MOBILE_APP';
export const SOURCE_TYPE_CARRIER_MOBILE_APP = 'CARRIER_MOBILE_APP';
// edi direction types
export const EDI_DIRECTION_TYPE_INBOUND = 'INBOUND';
export const EDI_DIRECTION_TYPE_OUTBOUND = 'OUTBOUND';
// maintenance types
export const CONFIGURATION_MAINTENANCE_TYPE_TRUCK = 'TRUCK';
export const CONFIGURATION_MAINTENANCE_TYPE_TRAILER = 'TRAILER';
export const CONFIGURATION_MAINTENANCE_TYPE_GENERAL = 'GENERAL';
// cross border integration type
export const CROSS_BORDER_INTEGRATION_TYPE_BORDER_CONNECT = 'BORDER_CONNECT';
// manifest types
export const ACI = 'ACI';
export const ACE = 'ACE';
// dispatch types
export const DISPATCH_TYPE_ROLL = 'ROLL';
export const DISPATCH_TYPE_COMMIT = 'COMMIT';
// priority types
export const PRIORITY_TYPE_LOW = 'LOW';
export const PRIORITY_TYPE_HIGH = 'HIGH';
export const PRIORITY_TYPE_MEDIUM = 'MEDIUM';
// trucking methods
export const TRUCKING_METHOD_DROP = 'DROP';
export const TRUCKING_METHOD_HOOK = 'HOOK';
export const TRUCKING_METHOD_LIFE_LOAD = 'LIFE_LOAD';
export const TRUCKING_METHOD_LIFE_UNLOAD = 'LIFE_UNLOAD';
